import React, { FC, useEffect, useState } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Button } from '@mantine/core'
import LeadCreatedNotification from './Components/LeadCreatedNotification'
import CounsellorAssignedNotification from '@/Components/Notifications/Components/CounsellorAssignedNotification'
import LeadConvertedNotification from '@/Components/Notifications/Components/LeadConvertedNotification'
import CounsellingConvertedNotification from '@/Components/Notifications/Components/CounsellingConvertedNotification'
import moment from 'moment'
import { useInterval } from '@mantine/hooks'
import TaskCreatedNotification from '@/Components/Notifications/Components/TaskCreatedNotification'
import TaskCreatedWithLeadNotification from '@/Components/Notifications/Components/TaskCreatedWithLeadNotification'
import CommentCreatedNotification from '@/Components/Notifications/Components/CommentCreatedNotification'
import CommentCreatedWithLeadNotification from '@/Components/Notifications/Components/CommentCreatedWithLeadNotification'
import CommentMentionNotification from '@/Components/Notifications/Components/CommentMentionNotification'
import CommentMentionWithLeadNotification from '@/Components/Notifications/Components/CommentMentionWithLeadNotification'
import AdmissionApplicationAppliedNotification from '@/Components/Notifications/Components/AdmissionApplicationAppliedNotification'
import AdmissionApplicationInterviewPendingNotification from '@/Components/Notifications/Components/AdmissionApplicationInterviewPendingNotification'
import AdmissionApplicationDecisionAwaitedNotification from '@/Components/Notifications/Components/AdmissionApplicationDecisionAwaitedNotification'
import AdmissionApplicationAdmittedNotification from '@/Components/Notifications/Components/AdmissionApplicationAdmittedNotification'
import AdmissionApplicationFeePaidNotification from '@/Components/Notifications/Components/AdmissionApplicationFeePaidNotification'
import VisaScheduledNotification from '@/Components/Notifications/Components/VisaScheduledNotification'
import VisaLodgedNotification from '@/Components/Notifications/Components/VisaLodgedNotification'
import VisaGrantedNotification from '@/Components/Notifications/Components/VisaGrantedNotification'
import VisaDeniedNotification from '@/Components/Notifications/Components/VisaDeniedNotification'
import AdmissionManagerAssignedNotification from '@/Components/Notifications/Components/AdmissionManagerAssignedNotification'
import VisaManagerAssignedNotification from '@/Components/Notifications/Components/VisaManagerAssignedNotification'
import LeadAcceptedNotification from '@/Components/Notifications/Components/LeadAcceptedNotification'
import AgencyCommentOnLeadNotification from '@/Components/Notifications/Components/AgencyCommentOnLeadNotification'
import AgentCommentOnLeadNotification from '@/Components/Notifications/Components/AgentCommentOnLeadNotification'
import AgentInviteAcceptedNotification from '@/Components/Notifications/Components/AgentInviteAcceptedNotification'
import AgentVisaTransitionNotification from '@/Components/Notifications/Components/AgentVisaTransitionNotification'
import VisitorLeadCreatedNotification from '@/Components/Notifications/Components/VisitorLeadCreatedNotification'
import IeltsLeadCreatedNotification from '@/Components/Notifications/Components/IeltsLeadCreatedNotification'
import B2BAnnouncementPublishedNotification from '@/Components/Notifications/Components/B2BAnnouncementPublishedNotification'
import LeadCounsellorAssignedNotification from '@/Components/Notifications/Components/LeadCounsellorAssignedNotification'
import PaymentReminderToLeadManagerNotification from '@/Components/Notifications/Components/PaymentReminderToLeadManagerNotification'
import LeadTransitionNotification from '@/Components/Notifications/Components/LeadTransitionNotification'
import LeadBranchChangedNotification from '@/Components/Notifications/Components/LeadBranchChangedNotification'
import LeadCounsellorChangedNotification from '@/Components/Notifications/Components/LeadCounsellorChangedNotification'
import LeadCourseAssignedNotification from '@/Components/Notifications/Components/LeadCourseAssignedNotification'
import LeadCourseUnassignedNotification from '@/Components/Notifications/Components/LeadCourseUnassignedNotification'
import CounsellingCreatedNotification from '@/Components/Notifications/Components/CounsellingCreatedNotification'
import CounsellingTransitionNotification from '@/Components/Notifications/Components/CounsellingTransitionNotification'
import CounsellingCounsellorChangedNotification from '@/Components/Notifications/Components/CounsellingCounsellorChangedNotification'
import AdmissionCreatedNotification from '@/Components/Notifications/Components/AdmissionCreatedNotification'
import AdmissionManagerChangedNotification from '@/Components/Notifications/Components/AdmissionManagerChangedNotification'
import AdmissionApplicationCreatedNotification from '@/Components/Notifications/Components/AdmissionApplicationCreatedNotification'
import VisaCreatedNotification from '@/Components/Notifications/Components/VisaCreatedNotification'
import VisaManagerChangedNotification from '@/Components/Notifications/Components/VisaManagerChangedNotification'
import IeltsLeadBranchChangedNotification from '@/Components/Notifications/Components/IeltsLeadBranchChangedNotification'
import IeltsLeadManagerAssignedNotification from '@/Components/Notifications/Components/IeltsLeadManagerAssignedNotification'
import IeltsLeadTransitionNotification from '@/Components/Notifications/Components/IeltsLeadTransitionNotification'
import VisitorLeadBranchChangedNotification from '@/Components/Notifications/Components/VisitorLeadBranchChangedNotification'
import VisitorLeadManagerAssignedNotification from '@/Components/Notifications/Components/VisitorLeadManagerAssignedNotification'
import VisitorLeadManagerChangedNotification from '@/Components/Notifications/Components/VisitorLeadManagerChangedNotification'
import VisitorLeadTransitionNotification from '@/Components/Notifications/Components/VisitorLeadTransitionNotification'
import VisitorLeadVisaManagerAssignedNotification from '@/Components/Notifications/Components/VisitorLeadVisaManagerAssignedNotification'
import VisitorLeadVisaManagerChangedNotification from '@/Components/Notifications/Components/VisitorLeadVisaManagerChangedNotification'
import WhatsappMessageReceivedNotification from '@/Components/Notifications/Components/WhatsappMessageReceivedNotification'
import InvoiceCreatedNotification from '@/Components/Notifications/Components/InvoiceCreatedNotification'
import InvoiceStatusTransitionNotification from '@/Components/Notifications/Components/InvoiceStatusTransitionNotification'
import InvoiceAcceptedOrRejectedNotification from '@/Components/Notifications/Components/InvoiceAcceptedOrRejectedNotification'
import AdmissionApplicationTransitionNotification from '@/Components/Notifications/Components/AdmissionApplicationTransitionNotification'
import VisaTransitionNotification from '@/Components/Notifications/Components/VisaTransitionNotification'
import DocumentUploadedNotification from '@/Components/Notifications/Components/DocumentUploadedNotification'
import IeltsLeadManagerChangedNotification from '@/Components/Notifications/Components/IeltsLeadManagerChangedNotification'
import TaskAssignedNotification from '@/Components/Notifications/Components/TaskAssignedNotification'
import TaskAssignedWithLeadNotification from '@/Components/Notifications/Components/TaskAssignedWithLeadNotification'
import AccountingPaymentCommentAddedNotification from '@/Components/Notifications/Components/AccountingPaymentCommentAddedNotification'
import PaymentApprovedNotification from '@/Components/Notifications/Components/PaymentApprovedNotification'
import ShareInfopediaNotification from '@/Components/Notifications/Components/ShareInfopediaNotification'
import AgentCommentOnVisitorLeadNotification from '@/Components/Notifications/Components/AgentCommentOnVisitorLeadNotification'
import AgencyCommentOnVisitorLeadNotification from '@/Components/Notifications/Components/AgencyCommentOnVisitorLeadNotification'
import Notification = App.Models.Notification

const Notifications: FC<{
  notification: Notification
  portal?: string
  disableRead?: boolean
}> = ({ notification, portal = 'Agency', disableRead = false }) => {
  const notificationAt = notification.data.event_at || parseInt(moment(notification.updated_at).format('X'))

  const [hover, setHover] = useState(false)
  const [eventAt, setEventAt] = useState(moment(notificationAt, 'X').fromNow())
  const interval = useInterval(() => setEventAt(moment(notificationAt + 1, 'X').fromNow()), 1000)

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [])

  let notificationJSX = <></>

  switch (notification.type) {
    case 'App\\Notifications\\B2B\\B2BAnnouncementPublishedNotification':
      notificationJSX = <B2BAnnouncementPublishedNotification notification={notification} />
      break
    case 'App\\Notifications\\B2B\\AgentInviteAcceptedNotification':
      notificationJSX = <AgentInviteAcceptedNotification notification={notification} />
      break
    case 'App\\Notifications\\Hub\\LeadAcceptedNotification':
      notificationJSX = <LeadAcceptedNotification notification={notification} />
      break
    case 'App\\Notifications\\B2B\\AgentCommentOnLeadNotification':
      notificationJSX = <AgentCommentOnLeadNotification notification={notification} />
      break
    case 'App\\Notifications\\Hub\\AgencyCommentOnLeadNotification':
      notificationJSX = <AgencyCommentOnLeadNotification notification={notification} />
      break
    case 'App\\Notifications\\Lead\\LeadCreatedNotification':
      notificationJSX = <LeadCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadCreatedNotification':
      notificationJSX = <VisitorLeadCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\IeltsLead\\IeltsLeadCreatedNotification':
      notificationJSX = <IeltsLeadCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadCounsellorAssignedNotification':
      notificationJSX = <LeadCounsellorAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Counselling\\CounsellorAssignedNotification':
      notificationJSX = <CounsellorAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadConvertedNotification':
      notificationJSX = <LeadConvertedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Counselling\\CounsellingConvertedNotification':
      notificationJSX = <CounsellingConvertedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\TaskCreatedNotification':
      notificationJSX = <TaskCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\TaskCreatedWithLeadNotification':
      notificationJSX = <TaskCreatedWithLeadNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\TaskAssignedNotification':
      notificationJSX = <TaskAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\TaskAssignedWithLeadNotification':
      notificationJSX = <TaskAssignedWithLeadNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Payment\\PaymentReminderToLeadManagerNotification':
      notificationJSX = <PaymentReminderToLeadManagerNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Payment\\PaymentApprovedNotification':
      notificationJSX = <PaymentApprovedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Payment\\AccountingPaymentCommentAddedNotification':
      notificationJSX = <AccountingPaymentCommentAddedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\Comment\\CommentCreatedNotification':
      notificationJSX = <CommentCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\Comment\\CommentCreatedWithLeadNotification':
      notificationJSX = <CommentCreatedWithLeadNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\Comment\\CommentMentionNotification':
      notificationJSX = <CommentMentionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Task\\Comment\\CommentMentionWithLeadNotification':
      notificationJSX = <CommentMentionWithLeadNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionManagerAssignedNotification':
      notificationJSX = <AdmissionManagerAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaManagerAssignedNotification':
      notificationJSX = <VisaManagerAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationAppliedNotification':
      notificationJSX = <AdmissionApplicationAppliedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationInterviewPendingNotification':
      notificationJSX = <AdmissionApplicationInterviewPendingNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationDecisionAwaitedNotification':
      notificationJSX = <AdmissionApplicationDecisionAwaitedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationAdmittedNotification':
      notificationJSX = <AdmissionApplicationAdmittedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationFeePaidNotification':
      notificationJSX = <AdmissionApplicationFeePaidNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaScheduledNotification':
      notificationJSX = <VisaScheduledNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaLodgedNotification':
      notificationJSX = <VisaLodgedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaGrantedNotification':
      notificationJSX = <VisaGrantedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaDeniedNotification':
      notificationJSX = <VisaDeniedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Hub\\AgentVisaTransitionNotification':
      notificationJSX = <AgentVisaTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadTransitionNotification':
      notificationJSX = <LeadTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadBranchChangedNotification':
      notificationJSX = <LeadBranchChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadCounsellorChangedNotification':
      notificationJSX = <LeadCounsellorChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadCourseAssignedNotification':
      notificationJSX = <LeadCourseAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Lead\\LeadCourseUnAssignedNotification':
      notificationJSX = <LeadCourseUnassignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Counselling\\CounsellingCreatedNotification':
      notificationJSX = <CounsellingCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Counselling\\CounsellingTransitionNotification':
      notificationJSX = <CounsellingTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Counselling\\CounsellorChangedNotification':
      notificationJSX = <CounsellingCounsellorChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionCreatedNotification':
      notificationJSX = <AdmissionCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionManagerChangedNotification':
      notificationJSX = <AdmissionManagerChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationCreatedNotification':
      notificationJSX = <AdmissionApplicationCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaCreatedNotification':
      notificationJSX = <VisaCreatedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaManagerChangedNotification':
      notificationJSX = <VisaManagerChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\IeltsLead\\IeltsLeadBranchChangedNotification':
      notificationJSX = <IeltsLeadBranchChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\IeltsLead\\IeltsLeadManagerAssignedNotification':
      notificationJSX = <IeltsLeadManagerAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\IeltsLead\\IeltsLeadManagerChangedNotification':
      notificationJSX = <IeltsLeadManagerChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\IeltsLead\\IeltsLeadTransitionNotification':
      notificationJSX = <IeltsLeadTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadBranchChangedNotification':
      notificationJSX = <VisitorLeadBranchChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadManagerAssignedNotification':
      notificationJSX = <VisitorLeadManagerAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadManagerChangedNotification':
      notificationJSX = <VisitorLeadManagerChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadTransitionNotification':
      notificationJSX = <VisitorLeadTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadVisaManagerAssignedNotification':
      notificationJSX = <VisitorLeadVisaManagerAssignedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\VisitorLead\\VisitorLeadVisaManagerChangedNotification':
      notificationJSX = <VisitorLeadVisaManagerChangedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Document\\DocumentUploadNotification':
      notificationJSX = <DocumentUploadedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Visa\\VisaTransitionNotification':
      notificationJSX = <VisaTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Admission\\AdmissionApplicationTransitionNotification':
      notificationJSX = <AdmissionApplicationTransitionNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\Whatsapp\\WhatsappMessageReceivedNotification':
      notificationJSX = <WhatsappMessageReceivedNotification notification={notification} portal={portal} />
      break
    case 'App\\Notifications\\B2B\\InvoiceCreatedNotification':
      notificationJSX = <InvoiceCreatedNotification notification={notification} />
      break
    case 'App\\Notifications\\B2B\\InvoiceStatusTransitionNotification':
      notificationJSX = <InvoiceStatusTransitionNotification notification={notification} />
      break
    case 'App\\Notifications\\Hub\\InvoiceAcceptedOrRejectedNotification':
      notificationJSX = <InvoiceAcceptedOrRejectedNotification notification={notification} />
      break
    case 'App\\Notifications\\B2B\\ShareInfopediaNotification':
      notificationJSX = <ShareInfopediaNotification notification={notification} />
      break
    case 'App\\Notifications\\B2B\\AgentCommentOnVisitorLeadNotification':
      notificationJSX = <AgentCommentOnVisitorLeadNotification notification={notification} />
      break
    case 'App\\Notifications\\Hub\\AgencyCommentOnVisitorLeadNotification':
      notificationJSX = <AgencyCommentOnVisitorLeadNotification notification={notification} />
      break
  }

  return (
    <HStack>
      <VStack
        spacing="2px"
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        style={{
          borderRadius: '12px',
          backgroundColor: hover ? XelaColor.Gray12 : 'transparent',
        }}
      >
        {notificationJSX}

        <HStack justifyContent="space-between" style={{ padding: '0 12px', minHeight: '30px' }}>
          <Typography variant="caption" color={XelaColor.Gray7}>
            {eventAt}
          </Typography>
          {!disableRead && (
            <Button
              size={'xs'}
              variant="white"
              color={'blueX'}
              radius="12px"
              onClick={() => {
                router.post(`/notifications/${notification.id}/mark-read`)
              }}
            >
              Mark read
            </Button>
          )}
        </HStack>
      </VStack>
    </HStack>
  )
}

export default Notifications
