import React, { FC } from 'react'
import { XelaColor } from '@/XelaReact'
import { Box } from '@mantine/core'

const XInitialAvatar: FC<{ name: string; c?: string; bg?: string }> = ({
  name,
  c = XelaColor.Blue3,
  bg = XelaColor.Blue11,
}) => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      borderRadius: 16,
      color: c,
      backgroundColor: bg,
      fontSize: 12,
      fontWeight: 600,
      flexShrink: 0,
    }}
  >
    {name
      .split(' ')
      .map((n) => n[0])
      .join('')}
  </Box>
)

export default XInitialAvatar
