import React, { FC, useEffect, useState } from 'react'
import { Button, HStack, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '@inertiajs/react'

import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import XNumberInput from '@/Mantine/XNumberInput'
import useLocation from '@/Hooks/useLocation'
import useFlash from '@/Hooks/useFlash'
import { Checkbox, LoadingOverlay, SelectItem } from '@mantine/core'
import axios from 'axios'
import {
  updateB2BLeadId,
  updateB2BSelectAgent,
  updateLeadFormSubmitted,
  updateShowB2BAddLeadModal,
} from '@/Store/B2B/b2bLeadSlice'
import { RootState } from '@/Store'
import XPhoneInput from '@/Mantine/XPhoneInput'
import XDatePicker from '@/Mantine/XDatePicker'
import moment from 'moment'
import Divider from '../../../XelaReact/Divider/Divider'
import { XTextarea } from '@/Mantine/XTextarea'
import { getOrderOfFieldByLabel, getSelectedOfFieldByLabel } from '@/helpers'
import { EntitySectionData } from '@/Types/EntitySubTypes'
import useMeta from '@/Hooks/useMeta'
import COUNTRY_FORMATS from '@/Constants/CountryFormats'
import XNationalInput from '@/Mantine/XNationalInput'
import useAuth from '@/Hooks/useAuth'
import Agent = App.Models.Agent

const AddNewLeadFromB2BForm: FC = () => {
  const auth = useAuth()
  const meta = useMeta()
  const flash = useFlash()
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null)
  const [countries, setCountries] = useState<SelectItem[]>([])
  const [relationshipManagers, setRelationshipManagers] = useState<SelectItem[]>([])
  const [secondCountries, setSecondCountries] = useState<SelectItem[]>([])
  const [thirdCountries, setThirdCountries] = useState<SelectItem[]>([])
  const b2bLeadStore = useSelector((state: RootState) => state.b2bLead)
  const [educationTab, setEducationTab] = React.useState('')
  const [languageTab, setLanguageTab] = React.useState('')
  const [familyTab, setFamilyTab] = React.useState('')
  const [fieldsData] = useState<EntitySectionData>(meta.entityForms['b2b_lead_form'])
  const [categoryData, setCategoryData] = useState<Record<string, boolean>>({})

  const { data, setData, post, errors, reset, recentlySuccessful, clearErrors } = useForm<{
    lead_source: string
    given_name: string
    surname: string
    phone: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    preferred_country: string
    preferred_country_code: string
    second_preferred_country: string
    second_preferred_country_code: string
    third_preferred_country: string
    third_preferred_country_code: string
    visa_type: string
    agent_id?: string
    middle_name: string
    date_of_birth: string
    gender: string
    martial_status: string
    alternate_phone: string
    alternate_country: string
    alternate_country_code: string
    alternate_country_calling_code: string
    permanent_address: string
    permanent_city: string
    permanent_pincode: string
    permanent_state: string
    permanent_country: string
    permanent_country_code: string
    preferred_course_type: string
    preferred_course_name: string
    tenth_school_name: string
    tenth_year: number | ''
    tenth_board: string
    tenth_percentage: number | ''
    tenth_medium_of_instruction: string
    tenth_result_date: string
    twelfth_school_name: string
    twelfth_year: number | ''
    twelfth_board: string
    twelfth_percentage: number | ''
    twelfth_stream: string
    twelfth_english_marks: string
    twelfth_medium_of_instruction: string
    twelfth_result_date: string
    bachelor_one_year: number | ''
    bachelor_one_college: string
    bachelor_one_course: string
    bachelor_one_percentage: number | ''
    bachelor_one_reappears: number | ''
    bachelor_one_duration: string
    bachelor_one_result_date: string
    bachelor_one_medium_of_instruction: string
    master_one_year: number | ''
    master_one_college: string
    master_one_course: string
    master_one_percentage: number | ''
    master_one_reappears: number | ''
    master_one_duration: string
    master_one_result_date: string
    master_one_medium_of_instruction: string
    diploma_one_year: number | ''
    diploma_one_college: string
    diploma_one_course: string
    diploma_one_percentage: number | ''
    diploma_one_reappears: number | ''
    diploma_one_duration: string
    diploma_one_level: string
    diploma_one_result_date: string
    diploma_one_medium_of_instruction: string
    other_educations: string
    language_ielts_overall_score: number | null | ''
    language_ielts_listening_score: number | null | ''
    language_ielts_reading_score: number | null | ''
    language_ielts_writing_score: number | null | ''
    language_ielts_speaking_score: number | null | ''
    language_ielts_test_date: string | null
    language_toefl_overall_score: number | null | ''
    language_toefl_reading_score: number | null | ''
    language_toefl_listening_score: number | null | ''
    language_toefl_speaking_score: number | null | ''
    language_toefl_writing_score: number | null | ''
    language_toefl_test_date: string | null
    language_pte_overall_score: number | null | ''
    language_pte_listening_score: number | null | ''
    language_pte_reading_score: number | null | ''
    language_pte_speaking_score: number | null | ''
    language_pte_writing_score: number | null | ''
    language_pte_test_date: string | null
    aptitude_gre_overall_score: number | null | ''
    aptitude_gre_verbal_score: number | null | ''
    aptitude_gre_quantitative_score: number | null | ''
    aptitude_gre_analytical_score: number | null | ''
    aptitude_gre_test_date: string | null
    aptitude_gmat_overall_score: number | null | ''
    aptitude_gmat_verbal_score: number | null | ''
    aptitude_gmat_quantitative_score: number | null | ''
    aptitude_gmat_test_date: string | null
    aptitude_sat_overall_score: number | null | ''
    aptitude_sat_english_score: number | null | ''
    aptitude_sat_math_score: number | null | ''
    aptitude_sat_test_date: string | null
    language_duolingo_test_date: string | null
    language_duolingo_overall_score: number | null | ''
    language_duolingo_listening_score: number | null | ''
    language_duolingo_reading_score: number | null | ''
    language_duolingo_writing_score: number | null | ''
    language_duolingo_speaking_score: number | null | ''
    language_duolingo_login_id: string | null
    language_duolingo_login_password: string | null
    passport_number: string
    passport_nationality: string
    passport_issue_date: string
    passport_expiry_date: string
    passport_issue_place: string
    passport_birth_place: string
    previous_passport_number: string
    is_visa_rejected_in_past: boolean
    visa_rejection_one_country: string
    visa_rejection_one_year: number | ''
    visa_rejection_one_reason: string
    work_experience_one_company_name: string
    work_experience_one_job_title: string
    work_experience_one_is_still_working: boolean
    work_experience_one_start_date: string
    work_experience_one_end_date: string
    father_name: string
    father_first_name: string
    father_last_name: string
    father_phone: string
    father_country_calling_code: string
    father_country_code: string
    father_country: string
    father_email: string
    father_dob: string
    father_occupation: string
    father_address: string
    father_aadhaar_uid: string
    mother_name: string
    mother_first_name: string
    mother_last_name: string
    mother_phone: string
    mother_country_calling_code: string
    mother_country_code: string
    mother_country: string
    mother_email: string
    mother_dob: string
    mother_occupation: string
    mother_address: string
    mother_aadhaar_uid: string
    marriage_date: string
    estimated_budget: number | '' | null
    national_id: string
    b2b_relationship_manager_id: string | null
  }>({
    lead_source: 'Sub-Agent', // Change this to 'B2B' once the B2B lead source is added to the database
    given_name: '',
    surname: '',
    phone: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    preferred_country: '',
    preferred_country_code: '',
    second_preferred_country: '',
    second_preferred_country_code: '',
    third_preferred_country: '',
    third_preferred_country_code: '',
    visa_type: 'Single',
    agent_id: '',
    preferred_course_type: '',
    preferred_course_name: '',
    middle_name: '',
    date_of_birth: '',
    gender: 'Male',
    martial_status: 'Not Married',
    alternate_phone: '',
    alternate_country: 'India',
    alternate_country_code: 'IN',
    alternate_country_calling_code: '+91',
    permanent_address: '',
    permanent_city: '',
    permanent_pincode: '',
    permanent_state: '',
    permanent_country: 'India',
    permanent_country_code: 'IN',
    tenth_school_name: '',
    tenth_year: '',
    tenth_board: '',
    tenth_percentage: '',
    tenth_medium_of_instruction: '',
    tenth_result_date: '',
    twelfth_school_name: '',
    twelfth_year: '',
    twelfth_board: '',
    twelfth_percentage: '',
    twelfth_stream: '',
    twelfth_english_marks: '',
    twelfth_medium_of_instruction: '',
    twelfth_result_date: '',
    bachelor_one_year: '',
    bachelor_one_college: '',
    bachelor_one_course: '',
    bachelor_one_percentage: '',
    bachelor_one_reappears: 0,
    bachelor_one_duration: '',
    bachelor_one_result_date: '',
    bachelor_one_medium_of_instruction: '',
    master_one_year: '',
    master_one_college: '',
    master_one_course: '',
    master_one_percentage: '',
    master_one_reappears: 0,
    master_one_duration: '',
    master_one_result_date: '',
    master_one_medium_of_instruction: '',
    diploma_one_year: '',
    diploma_one_college: '',
    diploma_one_course: '',
    diploma_one_percentage: '',
    diploma_one_reappears: 0,
    diploma_one_duration: '',
    diploma_one_level: '',
    diploma_one_result_date: '',
    diploma_one_medium_of_instruction: '',
    other_educations: '',
    language_ielts_overall_score: null,
    language_ielts_listening_score: null,
    language_ielts_reading_score: null,
    language_ielts_writing_score: null,
    language_ielts_speaking_score: null,
    language_ielts_test_date: '',
    language_toefl_overall_score: null,
    language_toefl_reading_score: null,
    language_toefl_listening_score: null,
    language_toefl_speaking_score: null,
    language_toefl_writing_score: null,
    language_toefl_test_date: '',
    language_pte_overall_score: null,
    language_pte_listening_score: null,
    language_pte_reading_score: null,
    language_pte_speaking_score: null,
    language_pte_writing_score: null,
    language_pte_test_date: '',
    aptitude_gre_overall_score: null,
    aptitude_gre_verbal_score: null,
    aptitude_gre_quantitative_score: null,
    aptitude_gre_analytical_score: null,
    aptitude_gre_test_date: '',
    aptitude_gmat_overall_score: null,
    aptitude_gmat_verbal_score: null,
    aptitude_gmat_quantitative_score: null,
    aptitude_gmat_test_date: '',
    aptitude_sat_overall_score: null,
    aptitude_sat_english_score: null,
    aptitude_sat_math_score: null,
    aptitude_sat_test_date: '',
    language_duolingo_test_date: '',
    language_duolingo_overall_score: null,
    language_duolingo_listening_score: null,
    language_duolingo_reading_score: null,
    language_duolingo_writing_score: null,
    language_duolingo_speaking_score: null,
    language_duolingo_login_id: '',
    language_duolingo_login_password: '',
    passport_number: '',
    passport_nationality: '',
    passport_issue_date: '',
    passport_expiry_date: '',
    passport_issue_place: '',
    passport_birth_place: '',
    previous_passport_number: '',
    is_visa_rejected_in_past: false,
    visa_rejection_one_country: '',
    visa_rejection_one_year: '',
    visa_rejection_one_reason: '',
    work_experience_one_company_name: '',
    work_experience_one_job_title: '',
    work_experience_one_is_still_working: false,
    work_experience_one_start_date: '',
    work_experience_one_end_date: '',
    father_name: '',
    father_first_name: '',
    father_last_name: '',
    father_phone: '',
    father_country_calling_code: '+91',
    father_country_code: 'IN',
    father_country: 'India',
    father_email: '',
    father_dob: '',
    father_occupation: '',
    father_address: '',
    father_aadhaar_uid: '',
    mother_name: '',
    mother_first_name: '',
    mother_last_name: '',
    mother_phone: '',
    mother_country_calling_code: '+91',
    mother_country_code: 'IN',
    mother_country: 'India',
    mother_email: '',
    mother_dob: '',
    mother_occupation: '',
    mother_address: '',
    mother_aadhaar_uid: '',
    marriage_date: '',
    estimated_budget: '',
    national_id: '',
    b2b_relationship_manager_id: null,
  })

  useEffect(() => {
    if (flash.action === 'lead:created' && recentlySuccessful) {
      const payload = flash.payload as {
        lead_id?: number
      } | null

      if (payload) {
        if (payload.lead_id) {
          dispatch(updateB2BLeadId(payload.lead_id))
          dispatch(updateLeadFormSubmitted(true))
          dispatch(updateB2BSelectAgent(null))
        } else {
          dispatch(updateShowB2BAddLeadModal(false))
        }
        clearErrors()
        reset()
      }
    }
  }, [flash.timestamp])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  useEffect(() => {
    axios
      .post<Record<string, string>>('/select/meta-options', {
        entity_name: 'countries',
        column_name: 'country',
        with_key: true,
      })
      .then((response) => {
        const countryList = Object.keys(response.data).map((key) => {
          return {
            value: key,
            label: response.data[key],
          }
        })

        setCountries(countryList)
        setSecondCountries(countryList)
        setThirdCountries(countryList)
      })
      .catch((error) => {
        console.error('Error fetching countries:', error)
      })
  }, [])

  useEffect(() => {
    if (b2bLeadStore.b2b_select_agent !== null) {
      setSelectedAgent(b2bLeadStore.b2b_select_agent)
      setData((prevData) => ({
        ...prevData,
        agent_id: b2bLeadStore.b2b_select_agent?.id.toString(),
      }))
    } else {
      setSelectedAgent(null)
    }
  }, [b2bLeadStore.b2b_select_agent])

  useEffect(() => {
    if (selectedAgent !== null) {
      const allRelationshipManagers: SelectItem[] = []
      if (selectedAgent.relationship_manager !== null) {
        allRelationshipManagers.push({
          value: String(selectedAgent?.relationship_manager?.id),
          label: selectedAgent?.relationship_manager?.name,
        })
      }

      if (selectedAgent.relationship_manager_one !== null) {
        allRelationshipManagers.push({
          value: String(selectedAgent?.relationship_manager_one?.id),
          label: selectedAgent?.relationship_manager_one?.name,
        })
      }

      if (selectedAgent.relationship_manager_two !== null) {
        allRelationshipManagers.push({
          value: String(selectedAgent?.relationship_manager_two?.id),
          label: selectedAgent?.relationship_manager_two?.name,
        })
      }

      if (selectedAgent.relationship_manager_three !== null) {
        allRelationshipManagers.push({
          value: String(selectedAgent?.relationship_manager_three?.id),
          label: selectedAgent?.relationship_manager_three?.name,
        })
      }

      if (auth.agency.id === 1111178) {
        if (selectedAgent.relationship_manager_four !== null) {
          allRelationshipManagers.push({
            value: String(selectedAgent?.relationship_manager_four?.id),
            label: selectedAgent?.relationship_manager_four?.name,
          })
        }

        if (selectedAgent.relationship_manager_five !== null) {
          allRelationshipManagers.push({
            value: String(selectedAgent?.relationship_manager_five?.id),
            label: selectedAgent?.relationship_manager_five?.name,
          })
        }

        if (selectedAgent.relationship_manager_six !== null) {
          allRelationshipManagers.push({
            value: String(selectedAgent?.relationship_manager_six?.id),
            label: selectedAgent?.relationship_manager_six?.name,
          })
        }

        if (selectedAgent.relationship_manager_seven !== null) {
          allRelationshipManagers.push({
            value: String(selectedAgent?.relationship_manager_seven?.id),
            label: selectedAgent?.relationship_manager_seven?.name,
          })
        }
      }

      // Unique Relationship Managers
      const uniqueRelationshipManagers = allRelationshipManagers.filter((item, index, self) => {
        return index === self.findIndex((t) => t.value === item.value)
      })

      if (uniqueRelationshipManagers && uniqueRelationshipManagers.length > 0) {
        setRelationshipManagers(uniqueRelationshipManagers)
      }
    }
  }, [selectedAgent !== null])

  useEffect(() => {
    if (fieldsData) {
      const categoryData: Record<string, boolean> = {}
      Object.entries(fieldsData).forEach(([category, formItems]) => {
        categoryData[category] = formItems.some((formItem) => formItem.selected)
      })
      setCategoryData(categoryData)
    }
  }, [fieldsData])

  const educationTabArrangement = [
    {
      label: 'Tenth',
      value: 'Tenth(HSC)',
      fieldName: 'tenth_details',
      order: getOrderOfFieldByLabel(fieldsData, 'education_details', 'tenth_details'),
    },
    {
      label: 'Twelfth',
      value: 'Twelfth(SSC)',
      fieldName: 'twelfth_details',
      order: getOrderOfFieldByLabel(fieldsData, 'education_details', 'twelfth_details'),
    },
    {
      label: 'Bachelors',
      value: 'Bachelors',
      fieldName: 'bachelors_details',
      order: getOrderOfFieldByLabel(fieldsData, 'education_details', 'bachelors_details'),
    },
    {
      label: 'Masters',
      value: 'Masters',
      fieldName: 'masters_details',
      order: getOrderOfFieldByLabel(fieldsData, 'education_details', 'masters_details'),
    },
    {
      label: 'Diplomas',
      value: 'Diplomas',
      fieldName: 'diploma_details',
      order: getOrderOfFieldByLabel(fieldsData, 'education_details', 'diploma_details'),
    },
    {
      label: 'Others',
      value: 'Others',
      fieldName: 'other_educations',
      order: getOrderOfFieldByLabel(fieldsData, 'education_details', 'other_educations'),
    },
  ]

  // Sort tabArrangement based on the order property
  educationTabArrangement.sort((a, b) => a.order - b.order)

  useEffect(() => {
    if (educationTabArrangement.length > 0) {
      setEducationTab(educationTabArrangement[0].label)
    }
  }, [])

  const languageTabArrangement = [
    {
      label: 'IELTS',
      value: 'IELTS',
      fieldName: 'ielts_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'ielts_details'),
    },
    {
      label: 'TOEFL',
      value: 'TOEFL',
      fieldName: 'toefl_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'toefl_details'),
    },
    {
      label: 'PTE',
      value: 'PTE',
      fieldName: 'pte_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'pte_details'),
    },
    {
      label: 'GRE',
      value: 'GRE',
      fieldName: 'gre_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'gre_details'),
    },
    {
      label: 'GMAT',
      value: 'GMAT',
      fieldName: 'gmat_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'gmat_details'),
    },
    {
      label: 'SAT',
      value: 'SAT',
      fieldName: 'sat_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'sat_details'),
    },
    {
      label: 'DUOLINGO',
      value: 'DUOLINGO',
      fieldName: 'duolingo_details',
      order: getOrderOfFieldByLabel(fieldsData, 'language_aptitude_test_details', 'duolingo_details'),
    },
  ]

  languageTabArrangement.sort((a, b) => a.order - b.order)

  useEffect(() => {
    if (languageTabArrangement.length > 0) {
      setLanguageTab(languageTabArrangement[0].label)
    }
  }, [])

  const familyTabArrangement = [
    {
      label: 'Father',
      value: 'Father',
      fieldName: 'father_details',
      order: getOrderOfFieldByLabel(fieldsData, 'family_details', 'father_details'),
    },
    {
      label: 'Mother',
      value: 'Mother',
      fieldName: 'mother_details',
      order: getOrderOfFieldByLabel(fieldsData, 'family_details', 'mother_details'),
    },
  ]

  familyTabArrangement.sort((a, b) => a.order - b.order)

  useEffect(() => {
    if (familyTabArrangement.length > 0) {
      setFamilyTab(familyTabArrangement[0].label)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        position: 'relative',
        width: '100%',
        height: '100%',
        gap: '12px',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          height: 0,
          gap: '12px',
          width: '100%',
          overflowY: 'auto',
          padding: '1px',
          paddingRight: '10px',
          margin: '-1px',
        }}
      >
        <VStack spacing={'12px'}>
          <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
            Personal Details
          </Typography>

          <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'given_name') }}>
              <XTextInput
                data-autofocus
                required
                label={'Given Name'}
                error={errors.given_name}
                value={data.given_name}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    given_name: event.target.value,
                  }))
                }}
              />
            </HStack>
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'middle_name') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'middle_name') }}>
                <XTextInput
                  data-autofocus
                  label={'Middle Name'}
                  error={errors.middle_name}
                  value={data.middle_name}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      middle_name: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'surname') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'surname') }}>
                <XTextInput
                  label={'Surname'}
                  error={errors.surname}
                  value={data.surname}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      surname: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'phone') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'phone') }}>
                <XPhoneInput
                  error={errors.phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                  checkMobileRemoteValidation={true}
                ></XPhoneInput>
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'national_id') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'national_id') }}>
                <XNationalInput
                  label={auth.agency.id === 1111181 ? 'Civil ID' : 'National ID'}
                  error={errors.national_id}
                  value={data.national_id}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      national_id: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'email') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'email') }}>
                <XTextInput
                  label={'Email'}
                  error={errors.email}
                  value={data.email}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      email: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'estimated_budget') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'estimated_budget') }}>
                <XNumberInput
                  icon={
                    <Typography variant={'body-small-bold'} color={XelaColor.Gray3}>
                      Rs
                    </Typography>
                  }
                  type={'number'}
                  label={'Estimated Budget'}
                  precision={1}
                  error={errors.estimated_budget}
                  defaultValue={data.estimated_budget}
                  onChange={(value) => {
                    if (value !== null) {
                      setData((prevData) => ({
                        ...prevData,
                        estimated_budget: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'gender') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'gender') }}>
                <XSelect
                  searchable
                  label={'Gender'}
                  error={errors.gender}
                  value={data.gender}
                  data={['Male', 'Female', 'Gender Fluid/Non Binary']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        gender: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') }}>
                <XDatePicker
                  dateParser={(dateString) => {
                    return moment(dateString, 'DD/MM/YYYY').toDate()
                  }}
                  value={data.date_of_birth ? moment(data.date_of_birth as string, 'YYYY-MM-DD').toDate() : null}
                  width="100%"
                  placeholder="DD/MM/YYYY"
                  valueFormat={'DD/MM/YYYY'}
                  maxDate={new Date()}
                  label={'Date of Birth'}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        date_of_birth: moment(value).format('YYYY-MM-DD'),
                      }))
                    }
                  }}
                  error={errors.date_of_birth}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') }}>
                <XSelect
                  searchable
                  label={'Marital Status'}
                  error={errors.martial_status}
                  value={data.martial_status}
                  data={['Married', 'Not Married']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        martial_status: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'marriage_date') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'marriage_date') }}>
                <XDatePicker
                  dateParser={(dateString) => {
                    return moment(dateString, 'DD/MM/YYYY').toDate()
                  }}
                  value={data.marriage_date ? moment(data.marriage_date as string, 'YYYY-MM-DD').toDate() : null}
                  width="100%"
                  placeholder="DD/MM/YYYY"
                  valueFormat={'DD/MM/YYYY'}
                  label={'Marriage Anniversary'}
                  maxDate={new Date()}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        marriage_date: moment(value).format('YYYY-MM-DD'),
                      }))
                    }
                  }}
                  error={errors.marriage_date}
                />
              </HStack>
            )}

            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'visa_type') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'visa_type') }}>
                <XSelect
                  searchable
                  label={'Visa Type'}
                  error={errors.visa_type}
                  value={data.visa_type}
                  onInit={(callback) => {
                    axios
                      .post('/select/meta-options', {
                        entity_name: 'leads',
                        column_name: 'visa_type',
                      })
                      .then((response) => {
                        callback(response.data)
                      })
                      .catch(() => {
                        callback([])
                      })
                  }}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        visa_type: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') }}>
                <XPhoneInput
                  label={'Alternate Phone'}
                  error={errors.alternate_phone}
                  dialCode={data.country_calling_code}
                  countryCode={data.country_code}
                  initialValue={data.alternate_phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      alternate_phone: number,
                      country_calling_code: dialCode,
                      country_code: countryCode,
                      country: country,
                    }))
                  }}
                  checkMobileRemoteValidation={true}
                ></XPhoneInput>
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'address') && (
              <HStack
                className={`col-span-2 order`}
                style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'address') }}
              >
                <XTextInput
                  label={'Address'}
                  error={errors.permanent_address}
                  value={data.permanent_address}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      permanent_address: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'country') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'country') }}>
                <XSelect
                  searchable
                  label={'Country'}
                  error={errors.country}
                  value={data.country_code}
                  data={countries}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        country_code: value,
                        country: countries.find((country) => country.value === value)?.label as string,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'state') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'state') }}>
                <XTextInput
                  label={'State'}
                  error={errors.permanent_state}
                  value={data.permanent_state}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      permanent_state: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'city') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'city') }}>
                <XTextInput
                  label={'City'}
                  error={errors.permanent_city}
                  value={data.permanent_city}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      permanent_city: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'pincode') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'pincode') }}>
                <XTextInput
                  label={'Pincode'}
                  error={errors.permanent_pincode}
                  value={data.permanent_pincode}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      permanent_pincode: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'b2b_relationship_manager_id') && (
              <HStack
                style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'b2b_relationship_manager_id') }}
              >
                <XSelect
                  clearable={true}
                  label={'Relationship Manager'}
                  error={errors.b2b_relationship_manager_id}
                  value={data.b2b_relationship_manager_id}
                  data={relationshipManagers}
                  onChange={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      b2b_relationship_manager_id: value,
                    }))
                  }}
                />
              </HStack>
            )}
            <HStack style={{ order: 9999 }}>
              <XSelect
                disabled
                searchable
                selectOnBlur={true}
                label={'Agent'}
                error={errors.agent_id}
                value={data.agent_id}
                data={
                  selectedAgent !== null
                    ? [
                        {
                          label: selectedAgent.name,
                          value: selectedAgent.id.toString(),
                        },
                      ]
                    : []
                }
              />
            </HStack>
          </div>
        </VStack>
        {categoryData.preference_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Preference Details
            </Typography>

            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'preference_details', 'preferred_country') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'preference_details', 'preferred_country') }}
                >
                  <XSelect
                    clearable={true}
                    searchable
                    label={'Preferred Country'}
                    error={errors.preferred_country}
                    value={data.preferred_country_code}
                    data={countries}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          preferred_country_code: value,
                          preferred_country: countries.find((country) => country.value === value)?.label as string,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'preference_details', 'second_preferred_country') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'preference_details', 'second_preferred_country'),
                  }}
                >
                  <XSelect
                    clearable={true}
                    searchable
                    label={'Second Preferred Country'}
                    error={errors.second_preferred_country}
                    value={data.second_preferred_country_code}
                    data={secondCountries}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          second_preferred_country_code: value,
                          second_preferred_country: secondCountries.find((country) => country.value === value)
                            ?.label as string,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'preference_details', 'third_preferred_country') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'preference_details', 'third_preferred_country') }}
                >
                  <XSelect
                    clearable={true}
                    searchable
                    label={'Third Preferred Country'}
                    error={errors.third_preferred_country}
                    value={data.third_preferred_country_code}
                    data={thirdCountries}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          third_preferred_country_code: value,
                          third_preferred_country: thirdCountries.find((country) => country.value === value)
                            ?.label as string,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'preference_details', 'preferred_course_type') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'preference_details', 'preferred_course_type') }}
                >
                  <XSelect
                    searchable
                    label={'Preferred Course Type'}
                    error={errors.preferred_course_type}
                    value={data.preferred_course_type}
                    onInit={(callback) => {
                      axios
                        .post('/select/meta-options', {
                          entity_name: 'leads',
                          column_name: 'preferred_course_type',
                        })
                        .then((response) => {
                          callback(response.data)
                        })
                        .catch((error) => {
                          console.log(error)
                          callback([])
                        })
                    }}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          preferred_course_type: value,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'preference_details', 'preferred_course_name') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'preference_details', 'preferred_course_name') }}
                >
                  <XTextInput
                    label={'Preferred Course Name'}
                    error={errors.preferred_course_name}
                    value={data.preferred_course_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        preferred_course_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {categoryData.education_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Education Details
            </Typography>

            <Tabs
              style={{
                width: '100%',
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: XelaColor.Gray12,
              }}
              items={educationTabArrangement
                .filter((tab) => getSelectedOfFieldByLabel(fieldsData, 'education_details', tab.fieldName))
                .map((tab) => new TabItem(tab.label, tab.value))}
              name="educational_details_tabs"
              defaultValue={educationTab}
              onChangeHandle={(value) => {
                setEducationTab(value)
              }}
            ></Tabs>
            {educationTab === 'Tenth' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={moment().year()}
                      data-autofocus
                      type={'number'}
                      label={'Tenth Year'}
                      error={errors.tenth_year}
                      defaultValue={data.tenth_year}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          tenth_year: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XSelect
                      searchable
                      label={'Tenth Board'}
                      error={errors.tenth_board}
                      value={data.tenth_board}
                      onInit={(callback) => {
                        axios
                          .post('/select/meta-options', {
                            entity_name: 'leads',
                            column_name: 'education_boards',
                          })
                          .then((response) => {
                            callback(response.data)
                          })
                          .catch((error) => {
                            console.log(error)
                            callback([])
                          })
                      }}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            tenth_board: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      max={100}
                      type={'number'}
                      precision={2}
                      label={'Tenth Percentage/CGPA'}
                      error={errors.tenth_percentage}
                      defaultValue={data.tenth_percentage}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          tenth_percentage: value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      label={'Tenth School Name'}
                      error={errors.tenth_school_name}
                      value={data.tenth_school_name}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          tenth_school_name: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Tenth Medium of Instruction'}
                      error={errors.tenth_medium_of_instruction}
                      value={data.tenth_medium_of_instruction}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          tenth_medium_of_instruction: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.tenth_result_date ? moment(data.tenth_result_date as string, 'YYYY-MM-DD').toDate() : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Tenth Result Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            tenth_result_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.tenth_result_date}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {educationTab === 'Twelfth' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={moment().year()}
                      type={'number'}
                      label={'Twelfth Year'}
                      error={errors.twelfth_year}
                      defaultValue={data.twelfth_year}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          twelfth_year: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XSelect
                      searchable
                      label={'Twelfth Board'}
                      error={errors.twelfth_board}
                      value={data.twelfth_board}
                      onInit={(callback) => {
                        axios
                          .post('/select/meta-options', {
                            entity_name: 'leads',
                            column_name: 'education_boards',
                          })
                          .then((response) => {
                            callback(response.data)
                          })
                          .catch((error) => {
                            console.log(error)
                            callback([])
                          })
                      }}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            twelfth_board: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      max={100}
                      type={'number'}
                      precision={2}
                      label={'Twelfth Percentage/CGPA'}
                      error={errors.twelfth_percentage}
                      defaultValue={data.twelfth_percentage}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          twelfth_percentage: value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XSelect
                      label={'Twelfth Stream'}
                      error={errors.twelfth_stream}
                      value={data.twelfth_stream}
                      data={['Arts', 'Commerce', 'Medicine', 'Non-Medicine', 'Science', 'Others']}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            twelfth_stream: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Twelfth School Name'}
                      error={errors.twelfth_school_name}
                      value={data.twelfth_school_name}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          twelfth_school_name: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Twelfth Medium of Instruction'}
                      error={errors.twelfth_medium_of_instruction}
                      value={data.twelfth_medium_of_instruction}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          twelfth_medium_of_instruction: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      label={'Twelfth English Marks'}
                      error={errors.twelfth_english_marks}
                      value={data.twelfth_english_marks}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          twelfth_english_marks: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.twelfth_result_date
                          ? moment(data.twelfth_result_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Twelfth Result Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            twelfth_result_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.twelfth_result_date}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {educationTab === 'Bachelors' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={moment().year()}
                      type={'number'}
                      label={'Bachelor Year'}
                      error={errors.bachelor_one_year}
                      defaultValue={data.bachelor_one_year}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          bachelor_one_year: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'College/University'}
                      error={errors.bachelor_one_college}
                      value={data.bachelor_one_college}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          bachelor_one_college: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Course Name'}
                      error={errors.bachelor_one_course}
                      value={data.bachelor_one_course}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          bachelor_one_course: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={100}
                      type={'number'}
                      precision={2}
                      label={'Percentage/CGPA'}
                      error={errors.bachelor_one_percentage}
                      defaultValue={data.bachelor_one_percentage}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          bachelor_one_percentage: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      max={20}
                      type={'number'}
                      label={'No of Reappears'}
                      error={errors.bachelor_one_reappears}
                      defaultValue={data.bachelor_one_reappears}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          bachelor_one_reappears: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XSelect
                      label={'Duration'}
                      error={errors.bachelor_one_duration}
                      value={data.bachelor_one_duration}
                      data={['3 yr', '4 yr', '5 yr']}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            bachelor_one_duration: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing={'16px'} alignItems="flex-start" width={'66%'}>
                  <HStack>
                    <XTextInput
                      label={'Medium of Instruction'}
                      error={errors.bachelor_one_medium_of_instruction}
                      value={data.bachelor_one_medium_of_instruction}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          bachelor_one_medium_of_instruction: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.bachelor_one_result_date
                          ? moment(data.bachelor_one_result_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Result Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            bachelor_one_result_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.bachelor_one_result_date}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {educationTab === 'Masters' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={moment().year()}
                      type={'number'}
                      label={'Master Year'}
                      error={errors.master_one_year}
                      defaultValue={data.master_one_year}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          master_one_year: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'College/University'}
                      error={errors.master_one_college}
                      value={data.master_one_college}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          master_one_college: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Course Name'}
                      error={errors.master_one_course}
                      value={data.master_one_course}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          master_one_course: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={100}
                      type={'number'}
                      precision={2}
                      label={'Percentage/CGPA'}
                      error={errors.master_one_percentage}
                      defaultValue={data.master_one_percentage}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          master_one_percentage: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      max={20}
                      type={'number'}
                      label={'No of Reappears'}
                      error={errors.master_one_reappears}
                      defaultValue={data.master_one_reappears}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          master_one_reappears: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XSelect
                      label={'Duration'}
                      error={errors.master_one_duration}
                      value={data.master_one_duration}
                      data={['1 yr', '2 yr', '3 yr']}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            master_one_duration: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing={'16px'} alignItems="flex-start" width={'67%'}>
                  <HStack>
                    <XTextInput
                      label={'Medium of Instruction'}
                      error={errors.master_one_medium_of_instruction}
                      value={data.master_one_medium_of_instruction}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          master_one_medium_of_instruction: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.master_one_result_date
                          ? moment(data.master_one_result_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Result Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            master_one_result_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.master_one_result_date}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {educationTab === 'Diplomas' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={moment().year()}
                      type={'number'}
                      label={'Diploma Year'}
                      error={errors.diploma_one_year}
                      defaultValue={data.diploma_one_year}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          diploma_one_year: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'College/University'}
                      error={errors.diploma_one_college}
                      value={data.diploma_one_college}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          diploma_one_college: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Course Name'}
                      error={errors.diploma_one_course}
                      value={data.diploma_one_course}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          diploma_one_course: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      max={100}
                      type={'number'}
                      precision={2}
                      label={'Percentage/CGPA'}
                      error={errors.diploma_one_percentage}
                      defaultValue={data.diploma_one_percentage}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          diploma_one_percentage: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      max={20}
                      type={'number'}
                      label={'No of Reappears'}
                      error={errors.diploma_one_reappears}
                      defaultValue={data.diploma_one_reappears}
                      onChange={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          diploma_one_reappears: value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XSelect
                      label={'Duration'}
                      error={errors.diploma_one_duration}
                      value={data.diploma_one_duration}
                      data={['1 yr', '2 yr', '3 yr']}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            diploma_one_duration: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing={'16px'} alignItems="flex-start">
                  <HStack>
                    <XSelect
                      label={'Level'}
                      error={errors.diploma_one_level}
                      value={data.diploma_one_level}
                      data={['12th', 'UG', 'PG']}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            diploma_one_level: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Medium of Instruction'}
                      error={errors.diploma_one_medium_of_instruction}
                      value={data.diploma_one_medium_of_instruction}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          diploma_one_medium_of_instruction: e.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.diploma_one_result_date
                          ? moment(data.diploma_one_result_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Result Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            diploma_one_result_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.diploma_one_result_date}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {educationTab === 'Others' && (
              <VStack spacing="12px">
                <HStack>
                  <XTextarea
                    minRows={4}
                    label={'Other Educations'}
                    error={errors.other_educations}
                    value={data.other_educations}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        other_educations: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </VStack>
            )}
          </VStack>
        )}
        {categoryData.language_aptitude_test_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Language Aptitude Test Details
            </Typography>

            <Tabs
              style={{
                width: '100%',
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: XelaColor.Gray12,
              }}
              items={languageTabArrangement
                .filter((tab) => getSelectedOfFieldByLabel(fieldsData, 'language_aptitude_test_details', tab.fieldName))
                .map((tab) => new TabItem(tab.label, tab.value))}
              name="language_aptitude_details_tabs"
              defaultValue={languageTab}
              onChangeHandle={(value) => {
                setLanguageTab(value)
              }}
            ></Tabs>
            {languageTab === 'IELTS' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_ielts_test_date
                          ? moment(data.language_ielts_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.language_ielts_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_ielts_overall_score}
                      defaultValue={data.language_ielts_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_ielts_listening_score}
                      defaultValue={data.language_ielts_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_ielts_reading_score}
                      defaultValue={data.language_ielts_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_ielts_writing_score}
                      defaultValue={data.language_ielts_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_ielts_speaking_score}
                      defaultValue={data.language_ielts_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_ielts_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'TOEFL' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_toefl_test_date
                          ? moment(data.language_toefl_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.language_toefl_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={2}
                      error={errors.language_toefl_overall_score}
                      defaultValue={data.language_toefl_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={2}
                      error={errors.language_toefl_reading_score}
                      defaultValue={data.language_toefl_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={2}
                      error={errors.language_toefl_listening_score}
                      defaultValue={data.language_toefl_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={2}
                      error={errors.language_toefl_speaking_score}
                      defaultValue={data.language_toefl_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={2}
                      error={errors.language_toefl_writing_score}
                      defaultValue={data.language_toefl_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_toefl_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'PTE' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_pte_test_date
                          ? moment(data.language_pte_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.language_pte_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={2}
                      error={errors.language_pte_overall_score}
                      defaultValue={data.language_pte_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={2}
                      error={errors.language_pte_listening_score}
                      defaultValue={data.language_pte_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={2}
                      error={errors.language_pte_speaking_score}
                      defaultValue={data.language_pte_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={2}
                      error={errors.language_pte_reading_score}
                      defaultValue={data.language_pte_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={2}
                      error={errors.language_pte_writing_score}
                      defaultValue={data.language_pte_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_pte_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'GRE' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.aptitude_gre_test_date
                          ? moment(data.aptitude_gre_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gre_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.aptitude_gre_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={2}
                      error={errors.aptitude_gre_overall_score}
                      defaultValue={data.aptitude_gre_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gre_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Verbal Score'}
                      precision={2}
                      error={errors.aptitude_gre_verbal_score}
                      defaultValue={data.aptitude_gre_verbal_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gre_verbal_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Quantitative Score'}
                      precision={2}
                      error={errors.aptitude_gre_quantitative_score}
                      defaultValue={data.aptitude_gre_quantitative_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gre_quantitative_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Analytical Score'}
                      precision={2}
                      error={errors.aptitude_gre_analytical_score}
                      defaultValue={data.aptitude_gre_analytical_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gre_analytical_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <div style={{ width: '100%' }}></div>
                </HStack>
              </VStack>
            )}
            {languageTab === 'GMAT' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.aptitude_gmat_test_date
                          ? moment(data.aptitude_gmat_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gmat_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.aptitude_gmat_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={2}
                      error={errors.aptitude_gmat_overall_score}
                      defaultValue={data.aptitude_gmat_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gmat_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Verbal Score'}
                      precision={2}
                      error={errors.aptitude_gmat_verbal_score}
                      defaultValue={data.aptitude_gmat_verbal_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gmat_verbal_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Quantitative Score'}
                      precision={2}
                      error={errors.aptitude_gmat_quantitative_score}
                      defaultValue={data.aptitude_gmat_quantitative_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_gmat_quantitative_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'SAT' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.aptitude_sat_test_date
                          ? moment(data.aptitude_sat_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_sat_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.aptitude_sat_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={2}
                      error={errors.aptitude_sat_overall_score}
                      defaultValue={data.aptitude_sat_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_sat_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'English Score'}
                      precision={2}
                      error={errors.aptitude_sat_english_score}
                      defaultValue={data.aptitude_sat_english_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_sat_english_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Math Score'}
                      precision={2}
                      error={errors.aptitude_sat_math_score}
                      defaultValue={data.aptitude_sat_math_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            aptitude_sat_math_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {languageTab === 'DUOLINGO' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      label={'Login ID'}
                      error={errors.language_duolingo_login_id}
                      value={data.language_duolingo_login_id}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          language_duolingo_login_id: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      label={'Password'}
                      error={errors.language_duolingo_login_password}
                      value={data.language_duolingo_login_password}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          language_duolingo_login_password: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XDatePicker
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={
                        data.language_duolingo_test_date
                          ? moment(data.language_duolingo_test_date as string, 'YYYY-MM-DD').toDate()
                          : null
                      }
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Test Date'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_test_date: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.language_duolingo_test_date}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Overall Score'}
                      precision={1}
                      error={errors.language_duolingo_overall_score}
                      defaultValue={data.language_duolingo_overall_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_overall_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Listening Score'}
                      precision={1}
                      error={errors.language_duolingo_listening_score}
                      defaultValue={data.language_duolingo_listening_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_listening_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Reading Score'}
                      precision={1}
                      error={errors.language_duolingo_reading_score}
                      defaultValue={data.language_duolingo_reading_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_reading_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Writing Score'}
                      precision={1}
                      error={errors.language_duolingo_writing_score}
                      defaultValue={data.language_duolingo_writing_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_writing_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XNumberInput
                      type={'number'}
                      label={'Speaking Score'}
                      precision={1}
                      error={errors.language_duolingo_speaking_score}
                      defaultValue={data.language_duolingo_speaking_score}
                      onChange={(value) => {
                        if (value !== null) {
                          setData((prevData) => ({
                            ...prevData,
                            language_duolingo_speaking_score: value,
                          }))
                        }
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
          </VStack>
        )}
        {categoryData.passport_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Passport Details
            </Typography>

            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_number') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_number') }}>
                  <XTextInput
                    data-autofocus
                    label={'Passport Number'}
                    error={errors.passport_number}
                    value={data.passport_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality') }}
                >
                  <XTextInput
                    label={'Passport Nationality'}
                    error={errors.passport_nationality}
                    value={data.passport_nationality}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_nationality: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date') }}
                >
                  <XDatePicker
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_expiry_date
                        ? moment(data.passport_expiry_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Expiry Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_expiry_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      }
                    }}
                    error={errors.passport_expiry_date}
                  />
                </HStack>
              )}

              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date') }}
                >
                  <XDatePicker
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_issue_date
                        ? moment(data.passport_issue_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Issue Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_issue_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      }
                    }}
                    error={errors.passport_issue_date}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_place_of_issue') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_place_of_issue') }}
                >
                  <XTextInput
                    label={'Passport Issue Place'}
                    error={errors.passport_issue_place}
                    value={data.passport_issue_place}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_issue_place: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_birth_place') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_birth_place') }}
                >
                  <XTextInput
                    label={'Passport Place of Birth'}
                    error={errors.passport_birth_place}
                    value={data.passport_birth_place}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_birth_place: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'previous_passport_number') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'previous_passport_number') }}
                >
                  <XTextInput
                    label={'Previous Passport Number, if any'}
                    error={errors.previous_passport_number}
                    value={data.previous_passport_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        previous_passport_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {categoryData.family_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Family Details
            </Typography>
            <Tabs
              style={{
                width: '100%',
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: XelaColor.Gray12,
              }}
              items={familyTabArrangement
                .filter((tab) => getSelectedOfFieldByLabel(fieldsData, 'family_details', tab.fieldName))
                .map((tab) => new TabItem(tab.label, tab.value))}
              name="family_details_edit_modal_tabs"
              defaultValue={familyTab}
              onChangeHandle={(value) => {
                setFamilyTab(value)
              }}
            ></Tabs>
            {familyTab === 'Father' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Father First Name'}
                      error={errors.father_first_name}
                      value={data.father_first_name}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_first_name: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Father Last Name'}
                      error={errors.father_last_name}
                      value={data.father_last_name}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_last_name: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XPhoneInput
                      error={errors.father_phone}
                      dialCode={data.father_country_calling_code}
                      countryCode={data.father_country_code?.toLowerCase()}
                      initialValue={data.father_phone}
                      onChange={(number, dialCode, countryCode, country) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_phone: number,
                          father_country_calling_code: dialCode,
                          father_country_code: countryCode,
                          father_country: country,
                        }))
                      }}
                    ></XPhoneInput>
                  </HStack>
                </HStack>
                <HStack spacing={'16px'} alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Father Email'}
                      error={errors.father_email}
                      value={data.father_email}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_email: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={data.father_dob ? moment(data.father_dob as string, 'YYYY-MM-DD').toDate() : null}
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Father DOB'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            father_dob: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.father_dob}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Father Occupation'}
                      error={errors.father_occupation}
                      value={data.father_occupation}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_occupation: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Father Aadhaar UID'}
                      error={errors.father_aadhaar_uid}
                      value={data.father_aadhaar_uid}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_aadhaar_uid: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack width="200%">
                    <XTextInput
                      data-autofocus
                      label={'Father Address'}
                      error={errors.father_address}
                      value={data.father_address}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          father_address: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
            {familyTab === 'Mother' && (
              <VStack spacing="12px">
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Mother First Name'}
                      error={errors.mother_first_name}
                      value={data.mother_first_name}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_first_name: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Mother Last Name'}
                      error={errors.mother_last_name}
                      value={data.mother_last_name}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_last_name: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XPhoneInput
                      label={'Mother Phone'}
                      error={errors.mother_phone}
                      dialCode={data.mother_country_calling_code}
                      countryCode={data.mother_country_code?.toLowerCase()}
                      initialValue={data.mother_phone}
                      onChange={(number, dialCode, countryCode, country) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_phone: number,
                          mother_country_calling_code: dialCode,
                          mother_country_code: countryCode,
                          mother_country: country,
                        }))
                      }}
                    ></XPhoneInput>
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Mother Email'}
                      error={errors.mother_email}
                      value={data.mother_email}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_email: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack>
                    <XDatePicker
                      maxDate={new Date()}
                      dateParser={(dateString) => {
                        return moment(dateString, 'DD/MM/YYYY').toDate()
                      }}
                      value={data.mother_dob ? moment(data.mother_dob as string, 'YYYY-MM-DD').toDate() : null}
                      width="100%"
                      placeholder="DD/MM/YYYY"
                      valueFormat={'DD/MM/YYYY'}
                      label={'Mother DOB'}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            mother_dob: moment(value).format('YYYY-MM-DD'),
                          }))
                        }
                      }}
                      error={errors.mother_dob}
                    />
                  </HStack>
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Mother Occupation'}
                      error={errors.mother_occupation}
                      value={data.mother_occupation}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_occupation: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <XTextInput
                      data-autofocus
                      label={'Mother Aadhaar UID'}
                      error={errors.mother_aadhaar_uid}
                      value={data.mother_aadhaar_uid}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_aadhaar_uid: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                  <HStack width="200%">
                    <XTextInput
                      data-autofocus
                      label={'Mother Address'}
                      error={errors.mother_address}
                      value={data.mother_address}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          mother_address: event.target.value,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            )}
          </VStack>
        )}
        {categoryData.visa_details && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Visa Details
            </Typography>
            <HStack>
              <Checkbox
                label={'Is Visa Rejected in Past'}
                color={'blueX'}
                checked={data.is_visa_rejected_in_past}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    is_visa_rejected_in_past: event.target.checked,
                  }))
                }}
              />
            </HStack>
            {data.is_visa_rejected_in_past && (
              <HStack spacing={'12px'}>
                <XSelect
                  searchable
                  data={Object.values(COUNTRY_FORMATS)}
                  label={'Rejection Country'}
                  value={data.visa_rejection_one_country}
                  error={errors.visa_rejection_one_country}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        visa_rejection_one_country: value,
                      }))
                    }
                  }}
                />
                <XNumberInput
                  type={'number'}
                  label={'Rejection Year'}
                  error={errors.visa_rejection_one_year}
                  defaultValue={data.visa_rejection_one_year}
                  onChange={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      visa_rejection_one_year: value,
                    }))
                  }}
                />
                <XTextInput
                  label={'Rejection Reason'}
                  error={errors.visa_rejection_one_reason}
                  value={data.visa_rejection_one_reason}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      visa_rejection_one_reason: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
          </VStack>
        )}
        {categoryData.work_experience && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Work Experiences
            </Typography>
            <VStack spacing="12px">
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XTextInput
                    data-autofocus
                    label={'Company Name'}
                    error={errors.work_experience_one_company_name}
                    value={data.work_experience_one_company_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_company_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack>
                  <XTextInput
                    label={'Job Title'}
                    error={errors.work_experience_one_job_title}
                    value={data.work_experience_one_job_title}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_job_title: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </HStack>
              <VStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <Divider variant={'dotted'} color={XelaColor.Gray11}></Divider>
                </HStack>
                <HStack>
                  <HStack>
                    <Checkbox
                      label={'I am currently working in this role.'}
                      color={'blueX'}
                      checked={data.work_experience_one_is_still_working}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_is_still_working: event.target.checked,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XDatePicker
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_start_date
                        ? moment(data.work_experience_one_start_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'Start Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_start_date: moment(value).format('YYYY-MM'),
                        }))
                      }
                    }}
                    error={errors.work_experience_one_start_date}
                  />
                </HStack>
                <HStack>
                  <XDatePicker
                    disabled={data.work_experience_one_is_still_working}
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_end_date
                        ? moment(data.work_experience_one_end_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'End Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_end_date: moment(value).format('YYYY-MM'),
                        }))
                      }
                    }}
                    error={errors.work_experience_one_end_date}
                  />
                </HStack>
              </HStack>
            </VStack>
          </VStack>
        )}
      </div>
      <HStack spacing={'12px'} justifyContent="flex-end">
        <Button
          tabIndex={-1}
          label={'Cancel'}
          variant={'secondary'}
          onClick={() => {
            dispatch(updateShowB2BAddLeadModal(false))
            dispatch(updateB2BSelectAgent(null))
            clearErrors()
            reset()
          }}
        ></Button>
        <Button
          label={'Create'}
          onClick={() => {
            if (selectedAgent !== null) {
              setLoading(true)
              post(route('b2b.leads.quick-create'), {
                preserveState: true,
                preserveScroll: true,
                onFinish: () => {
                  setLoading(false)
                },
              })
            }
          }}
        ></Button>
      </HStack>
    </div>
  )
}

export default AddNewLeadFromB2BForm
