import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { LoadingOverlay } from '@mantine/core'
import { useForm } from '@inertiajs/react'
import { closeAllModals } from '@mantine/modals'
import { XTextarea } from '@/Mantine/XTextarea'
import AccountingComment = App.Models.AccountingComment

const EditAccountingCommentDrawer: FC<{ comment: AccountingComment }> = ({ comment }) => {
  const [loading, setLoading] = useState(false)

  const { data, setData, post, reset, errors, clearErrors, recentlySuccessful } = useForm<{
    comment: string
  }>({
    comment: comment.comment,
  })

  useEffect(() => {
    if (recentlySuccessful) {
      closeAllModals()
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  return (
    <VStack spacing="12px">
      <HStack>
        <Typography variant="subheadline">Edit Comment</Typography>
      </HStack>
      <HStack>
        <Divider variant="dotted"></Divider>
      </HStack>
      <VStack spacing="12px" style={{ padding: 2, position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
        <VStack spacing="12px" style={{ minHeight: 150 }}>
          <HStack>
            <Typography variant="body-small-bold" color={XelaColor.Gray4}>
              Comment
            </Typography>
          </HStack>
          <HStack spacing="12px">
            <XTextarea
              error={errors.comment}
              placeholder="Add a comment here..."
              minRows={4}
              value={data.comment}
              onChange={(e) => {
                setData('comment', e.currentTarget.value)
              }}
            ></XTextarea>
          </HStack>
        </VStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <HStack spacing="12px" justifyContent="flex-end">
          <Button
            label={'Cancel'}
            variant={'secondary'}
            onClick={() => {
              closeAllModals()
              clearErrors()
              reset()
            }}
          />
          <Button
            label={'Edit'}
            onClick={(e) => {
              // setLoading(true)
              post(route('accounting.comments.edit', { id: comment.id }), {
                preserveState: false,
                preserveScroll: false,
                // onFinish: () => {
                //   setLoading(false)
                // },
              })
            }}
          ></Button>
        </HStack>
      </VStack>
    </VStack>
  )
}

export default EditAccountingCommentDrawer
