import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const PaymentApprovedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        if (notification.data.lead_id === null) return
        if (notification.data.lead_type === 'App\\Models\\VisitorLead') {
          router.visit(
            route('visitor-leads.id', {
              id: notification.data.lead_id,
              show_tab: 'payments',
            })
          )
        } else if (notification.data.lead_type === 'App\\Models\\IeltsLead') {
          router.visit(
            route('ielts-leads.id', {
              id: notification.data.lead_id,
              show_tab: 'payments',
            })
          )
        } else {
          router.visit(
            route('leads.id', {
              id: notification.data.lead_id,
              show_tab: 'payments',
            })
          )
        }
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small" color={XelaColor.Pink3}>
          {notification.data.approved_by_name}
        </Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          approved a payment
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Gray3}>
          {notification.data.payment_type ? `#${notification.data.payment_type}` : ''}
        </Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          of amount {notification.data.payment_amount} for
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
    </VStack>
  )
}

export default PaymentApprovedNotification
