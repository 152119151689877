import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, ChipX, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, Drawer, Kbd, LoadingOverlay, SimpleGrid } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { router } from '@inertiajs/react'
import axios from 'axios'
import { XTextarea } from '@/Mantine/XTextarea'
import {
  updateSelectedCustomerPayment,
  updateSelectedCustomerPaymentId,
  updateShowAccountingCommentDrawer,
} from '@/Store/paymentSlice'
import AccountingCommentCard from '@/Pages/Accounting/Drawer/AccountingCommentCard'
import moment from 'moment'
import AccountingComment = App.Models.AccountingComment

const AccountingCommentsDrawer: FC = () => {
  const dispatch = useDispatch()

  const isMac = window.navigator.userAgent.toUpperCase().indexOf('MAC') >= 0

  const paymentStore = useSelector((state: RootState) => state.payment)
  const commentRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState<AccountingComment[]>([])
  const [newComment, setNewComment] = useState('')

  const onCloseHandler = () => {
    setLoading(false)
    dispatch(updateShowAccountingCommentDrawer(false))
    dispatch(updateSelectedCustomerPayment(null))
    dispatch(updateSelectedCustomerPaymentId(null))
    setNewComment('')

    router.reload()
  }

  const fetchComments = () => {
    setLoading(true)
    axios
      .get<AccountingComment[]>(route('accounting.comments.show', { id: paymentStore.selected_customer_payment?.id }))
      .then((response) => {
        setComments(response.data)
      })
      .catch((error) => {
        console.error('Error fetching Comments:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (paymentStore.selected_customer_payment) {
      fetchComments()
    }
  }, [paymentStore.selected_customer_payment])

  useEffect(() => {
    commentRef.current?.scrollTo({
      behavior: 'smooth',
      top: commentRef.current.scrollHeight,
    })
  }, [comments.length])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      position="right"
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={900}
      opened={paymentStore.show_accounting_comment_drawer}
      onClose={() => {
        onCloseHandler()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack>
          <HStack>
            <Typography variant={'subheadline'}>Payment Details</Typography>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <HStack justifyContent={'flex-end'} spacing={'12px'}>
              <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
                Approval Status:
              </Typography>
              {paymentStore.selected_customer_payment &&
                paymentStore.selected_customer_payment?.approval_status === 'Pending' && (
                  <ChipX
                    size={'small'}
                    label={'Pending'}
                    deactiveBg={XelaColor.Orange11}
                    deactiveLabelColor={XelaColor.Orange1}
                  ></ChipX>
                )}
              {paymentStore.selected_customer_payment &&
                paymentStore.selected_customer_payment?.approval_status === 'Approved' && (
                  <ChipX
                    size={'small'}
                    label={'Approved'}
                    deactiveBg={XelaColor.Green11}
                    deactiveLabelColor={XelaColor.Green1}
                  ></ChipX>
                )}
              {paymentStore.selected_customer_payment &&
                paymentStore.selected_customer_payment?.approval_status === 'Rejected' && (
                  <ChipX
                    size={'small'}
                    label={'Rejected'}
                    deactiveBg={XelaColor.Red11}
                    deactiveLabelColor={XelaColor.Red1}
                  ></ChipX>
                )}
              {paymentStore.selected_customer_payment &&
                paymentStore.selected_customer_payment?.approval_status === 'Archived' && (
                  <ChipX
                    size={'small'}
                    label={'Archived'}
                    deactiveBg={XelaColor.Purple11}
                    deactiveLabelColor={XelaColor.Purple1}
                  ></ChipX>
                )}
            </HStack>
            <Button
              size={'small'}
              variant={'secondary'}
              label={'Close'}
              onClick={() => {
                onCloseHandler()
              }}
            ></Button>
          </HStack>
        </HStack>

        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>

        <SimpleGrid
          cols={4}
          style={{
            padding: '10px',
            cursor: 'pointer',
            width: '100%',
          }}
          spacing={4}
        >
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Lead Name:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.lead.name}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Lead Phone:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment &&
                paymentStore.selected_customer_payment?.lead.country_calling_code}
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.lead.phone}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Type:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.payment_type}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Amount:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.currency}{' '}
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.amount}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Payment Mode:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.payment_mode}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Payment Date:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {moment(paymentStore.selected_customer_payment?.payment_date).format('DD MMM YYYY')}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Payment ID:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.payment_reference_id}
            </Typography>
          </HStack>
          <HStack spacing={'4px'}>
            <Typography variant={'body-small-bold'} color={XelaColor.Gray4}>
              Received By:
            </Typography>
            <Typography variant={'body-small'} color={XelaColor.Gray3}>
              {paymentStore.selected_customer_payment && paymentStore.selected_customer_payment?.received_by_name}
            </Typography>
          </HStack>
        </SimpleGrid>

        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>

        <HStack>
          <Typography variant="subheadline">Comments</Typography>
        </HStack>

        <VStack
          spacing="12px"
          style={{
            flex: '1 1 auto',
            overflowY: 'auto',
            paddingRight: '10px',
            height: '0',
            position: 'relative',
            padding: '2px',
          }}
        >
          <LoadingOverlay visible={loading} loaderProps={{ color: 'blueX' }}></LoadingOverlay>
          <VStack height="100%" spacing="8px" bg={XelaColor.White} borderRadius="12px" style={{ padding: 8 }}>
            <Box
              ref={commentRef}
              className="swift-chat"
              style={{
                height: 0,
                flex: '1 1 auto',
                width: '100%',
                minHeight: '150px',
                overflowY: 'auto',
                paddingRight: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: 8,
              }}
            >
              {!loading && comments.length === 0 && (
                <VStack alignItems="center" justifyContent="center" height="100%">
                  <Typography variant="body-bold" color={XelaColor.Gray3}>
                    {'No comments yet'}
                  </Typography>
                </VStack>
              )}
              {comments.length > 0 &&
                comments.map((comment) => <AccountingCommentCard key={comment.id} comment={comment} />)}
            </Box>

            <HStack>
              <XTextarea
                placeholder="Leave a comment here..."
                minRows={3}
                value={newComment}
                onChange={(e) => {
                  setNewComment(e.target.value)
                }}
                onKeyDown={(event) => {
                  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                    if (event.altKey || event.ctrlKey) {
                      setNewComment((prevData) => {
                        return prevData + '\r\n'
                      })
                    } else {
                      if (newComment) {
                        router.post(
                          route('accounting.comment', { id: paymentStore.selected_customer_payment?.id }),
                          {
                            comment: newComment,
                          },
                          {
                            preserveState: true,
                            preserveScroll: true,
                            onFinish: () => {
                              setNewComment('')
                              fetchComments()
                            },
                          }
                        )
                      }
                    }
                    event.preventDefault()
                  }
                }}
              ></XTextarea>
            </HStack>
            <HStack spacing="12px" justifyContent="flex-end">
              <HStack justifyContent="flex-end">
                <Typography variant="caption" color={XelaColor.Gray6}>
                  {isMac ? (
                    <>
                      <Kbd>alt</Kbd> + <Kbd>enter</Kbd>
                    </>
                  ) : (
                    <>
                      <Kbd>ctrl</Kbd> + <Kbd>enter</Kbd>
                    </>
                  )}
                  &nbsp; to add new line
                </Typography>
              </HStack>
              <Button
                label="Comment"
                size="small"
                variant="secondary"
                onClick={() => {
                  if (newComment) {
                    router.post(
                      route('accounting.comment', { id: paymentStore.selected_customer_payment?.id }),
                      {
                        comment: newComment,
                      },
                      {
                        preserveState: true,
                        preserveScroll: true,
                        onFinish: () => {
                          setNewComment('')
                          fetchComments()
                        },
                      }
                    )
                  }
                }}
              ></Button>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default AccountingCommentsDrawer
