import * as React from 'react'

function IconEdit1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="13" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.6 14.9V11.0414L10.5 1.14142L14.3586 5L4.45858 14.9H0.6Z" stroke="#003CFF" strokeWidth="1.2" />
    </svg>
  )
}

export default IconEdit1
