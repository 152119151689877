import React, { FC } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { closeAllModals } from '@mantine/modals'
import useWidthResize from '@/Hooks/useWidthResize'

const StatusConfirmModal: FC<{
  title: string
  message: string
  onConfirm: () => void
  onCancel?: () => void
}> = (props) => {
  const width = useWidthResize()

  return (
    <VStack spacing="12px">
      <HStack>
        <Typography variant="subheadline" color={XelaColor.Gray3}>
          {props.title}
        </Typography>
      </HStack>
      <HStack>
        <Divider variant={'dotted'} color={XelaColor.Gray10}></Divider>
      </HStack>
      <HStack
        alignItems="flex-start"
        style={{
          height: width > 1280 ? '50px' : '0px',
        }}
      >
        <Typography variant="body" color={XelaColor.Gray6}>
          {props.message}
        </Typography>
      </HStack>
      <HStack justifyContent={'flex-end'} style={{ paddingTop: '20px' }} spacing="12px">
        <Button
          label="Cancel"
          variant="secondary"
          onClick={() => {
            if (props.onCancel) props.onCancel()
            closeAllModals()
          }}
        />
        <Button
          label="Confirm"
          variant="primary"
          onClick={() => {
            props.onConfirm()
            closeAllModals()
          }}
        />
      </HStack>
    </VStack>
  )
}

export default StatusConfirmModal
