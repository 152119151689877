import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Box } from '@mantine/core'
import Notification = App.Models.Notification

const PaymentReminderToLeadManagerNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        if (notification.data.notification_redirect_to == 'leads' && notification.data.lead_id !== null) {
          if (notification.data.lead_type === 'App\\Models\\VisitorLead') {
            router.visit(
              route('visitor-leads.id', {
                id: notification.data.lead_id,
                show_tab: 'payments',
              })
            )
          } else if (notification.data.lead_type === 'App\\Models\\IeltsLead') {
            router.visit(
              route('ielts-leads.id', {
                id: notification.data.lead_id,
                show_tab: 'payments',
              })
            )
          } else {
            router.visit(
              route('leads.id', {
                id: notification.data.lead_id,
                show_tab: 'payments',
              })
            )
          }
        }

        if (notification.data.notification_redirect_to == 'accounting') {
          router.visit(route('accounting'))
        }
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small" color={XelaColor.Pink3}>
          {notification.data.comment_by}
        </Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          comment on a payment {notification.data.payment_type ? `#${notification.data.payment_type}` : ''} for
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
      <HStack spacing="12px" height="100%" style={{ padding: '6px 12px' }}>
        <Box
          style={{
            width: '8px',
            height: '30px',
            backgroundColor: XelaColor.Blue3,
            borderRadius: '4px',
          }}
        ></Box>
        <HStack
          spacing="3px"
          style={{
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <VStack>
            <Typography variant="caption" color={XelaColor.Gray6}>
              {notification.data.comment}
            </Typography>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default PaymentReminderToLeadManagerNotification
