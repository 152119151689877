import * as React from 'react'

function IconBin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.5 3.5h12v10a1 1 0 01-1 1h-10a1 1 0 01-1-1v-10z" fill="#FFFFFF" />
      <path
        d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15M7.5 7v5m-3-3v3m6-3v3m-9-8.5v10a1 1 0 001 1h10a1 1 0 001-1v-10h-12z"
        stroke="#F42829"
      />
    </svg>
  )
}

export default IconBin
